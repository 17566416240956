import React, { CSSProperties } from 'react';

import './BasicButton.scss';

export type BasicButtonProps = {
  text: string;
  onClick?(e?: any): void;
  className?: string;
  background?: string;
  disabled?: boolean;
  style?: CSSProperties;
  icon?: any;
  type?: 'button' | 'submit' | 'reset';
};

// NOTE: Default background color is #2574FB

const BasicButton = ({
  text,
  onClick,
  className = '',
  background,
  disabled,
  style,
  icon,
  type = 'button',
}: BasicButtonProps) => (
  <button
    type={type}
    className={`basic-button ${className}`}
    onClick={onClick}
    tabIndex={0}
    style={{
      backgroundColor: background,
      ...(disabled ? { opacity: 0.3 } : {}),
      outline: 'none',
      ...style,
    }}
    disabled={disabled}
  >
    {icon && icon}
    {text}
  </button>
);

export default BasicButton;
