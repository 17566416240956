import React, { useState, useEffect } from 'react';
import './UserRegisterSuccess.scss';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import greenTick from '../../../assets/icons/check-green.svg';
import crossIcon from '../../../assets/icons/cross.png';
import { TRANSLATIONS } from '../../../types/enums';

type Props = {
  pin: number | null;
  name: string;
  position: string;
  permissionsInString: string;
  close?: () => void;
  error?: string | null;
  onRetry?: () => void;
};

const UserRegisterSuccess = (props: Props) => {
  const {
    pin,
    name,
    position,
    permissionsInString,
    close,
    error = null,
    onRetry,
  } = props;

  const { t } = useTranslation();
  const [hasError, setHasError] = useState<boolean>(!!error);

  useEffect(() => {
    setHasError(!!error);
  }, [error]);

  return (
    <Modal
      onOverlayClick={() => {
        if (close) {
          close();
        }
      }}
      modalContainerStyles={{
        width: '40vw',
        left: '30vw',
        height: '50vh',
        top: '25vh',
      }}
    >
      {hasError ? (
        <div className="mainContainer">
          <img
            src={crossIcon}
            style={{
              width: '50px',
              height: '50px',
            }}
            alt="Error"
          />
          <span className="labelForPinDetails">{t(TRANSLATIONS.ERROR)}</span>
          <p className="labelForPinDetails">{error || t(TRANSLATIONS.ERROR)}</p>

          {onRetry && (
            <button
              className="retryButton"
              onClick={onRetry}
            >
              {t(TRANSLATIONS.RETRY)}
            </button>
          )}
        </div>
      ) : (
        <div className="mainContainer">
          <img
            src={greenTick}
            style={{
              width: '50px',
              height: '50px',
            }}
            alt="Success"
          />
          <span>{t(TRANSLATIONS.PIN_CREATED_SUCCESSFULLY)}</span>
          <h1>{`${pin}`}</h1>

          <div className="detailsContainer">
            <div className="labelValueContainer">
              <span className="labelForPinDetails">{t(TRANSLATIONS.NAME)}(s)</span>
              <span className="valueForPinDetails">{name || '-'}</span>
            </div>

            <div className="labelValueContainer">
              <span className="labelForPinDetails">{t(TRANSLATIONS.POSITION)}</span>
              <span className="valueForPinDetails">{position || '-'}</span>
            </div>

            <div
              className="labelValueContainer"
              style={{
                marginTop: '20px',
              }}
            >
              <span className="labelForPinDetails">{t(TRANSLATIONS.PERMISSIONS)}</span>
              <span className="valueForPinDetails">{permissionsInString || '-'}</span>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default UserRegisterSuccess;
