import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TRANSLATIONS, FETCH_STATUS } from 'types/enums';
import HighlightButton from 'components/Button/HighlightButton';
import Input from 'components/Input';
import request from 'utils/request';
import 'features/internal-users/user/LinkAccounts/LinkAccounts.scss';
import { isUserAuthenticated } from 'store/actions/user.actions';
import { useDispatch } from 'react-redux';

function LinkAccounts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.NO_ACTIVE_REQUEST);
  const [safetyEmail, setSafetyEmail] = useState('');
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const getSessionData = async () => {
      try {
        const response = await request.get('/api/v1/getTempSessionSSO');
        if (!response.user) {
          navigate('/login');
          return;
        }
        setUser(response.user);
      } catch (e) {
        console.error('Error fetching session data:', e);
        navigate('/login');
      }
    };

    getSessionData();
  }, [navigate]);

  const handleLinkAccounts = async () => {
    try {
      setFetchStatus(FETCH_STATUS.PENDING);
      await request.post('/api/v1/users/mergeSSOAccount', {
        email: safetyEmail,
      });
      console.log('Navigation data:', {
        email: safetyEmail,
        emailSSO: user?.email,
        mergeSSO: true,
      });
      navigate('/MfaVerification', {
        state: {
          email: safetyEmail,
          emailSSO: user?.email,
          mergeSSO: true,
        },
      });

      setFetchStatus(FETCH_STATUS.SUCCESS);
    } catch (e) {
      setFetchStatus(FETCH_STATUS.FAIL);
      if (e.message === 'Email does not exist or already has been merged') {
        setError('unable to merge');
      } else {
        setError('network');
      }
      console.error('Error linking accounts:', error);
    }
  };

  const handleContinueWithoutLinking = async () => {
    const confirmMessage = t(TRANSLATIONS.ARE_YOU_SURE_YOU_WANT_TO_CONTINUE_WITHOUT_LINKING);

    if (window.confirm(confirmMessage)) {
      await request.post('/api/v1/users/registerSSOAccount', { email: user?.email, userName: user.userName, isWeb: true });
    }
    await dispatch(isUserAuthenticated());
    navigate('/');
  };

  const isLoading = fetchStatus === FETCH_STATUS.PENDING;

  return (
    <div className="linkaccounts">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 card main-card-container">
            <p className="link-accounts-text" style={{ marginBottom: 10 }}>
              {t(TRANSLATIONS.LINK_YOUR_ACCOUNT)}
            </p>
            <p className="link-accounts-text" style={{ marginBottom: 10 }}>
              {t(TRANSLATIONS.WE_COULD_NOT_FIND_AN_ACCOUNT_WITH_EMAIL)} {user?.email || 'SSOEMAIL'}
            </p>
            <p className="link-accounts-text" style={{ marginBottom: 10 }}>
              {t(TRANSLATIONS.IF_YOU_HAVE_PREVIOUS_SAFETY360_ACCOUNT_ENTER_EMAIL)}
            </p>
            <p className="link-accounts-text" style={{ marginBottom: 10 }}>
              {t(TRANSLATIONS.THIS_IS_THE_ONLY_CHANCE_TO_LINK_A_PREVIOUS_ACCOUNT)}
            </p>
            <Input
              className="input-link-accounts"
              type="email"
              value={safetyEmail}
              onChange={e => setSafetyEmail(e.target.value)}
              disabled={isLoading}
              placeholder={t(TRANSLATIONS.ENTER_YOUR_EMAIL)}
              error={error === 'unable to merge'
                ? t(TRANSLATIONS.EMAIL_NOT_FOUND_BLOQUED_OR_MERGED_PLEASE_CONTACT_SUPPORT)
                : error === 'network'
                  ? t(TRANSLATIONS.ERROR)
                  : undefined}
            />
            <div className="button-container">
              <HighlightButton
                text={t(TRANSLATIONS.LINK_YOUR_ACCOUNT).toUpperCase()}
                className="main-action"
                onClick={handleLinkAccounts}
                disabled={!safetyEmail}
              />
              <HighlightButton
                text={t(TRANSLATIONS.CONTINUE_WITHOUT_LINKING).toUpperCase()}
                className="main-action"
                onClick={handleContinueWithoutLinking}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="footer">
            <p className="footer-text"> powered by ARVOLUTION </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinkAccounts;
