import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { upperFirst } from 'lodash';

import NavigationDrawerWithSideMenu from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import SelectDropdown from 'components/Dropdown/SelectDropdown';
import MonthlyTimeFilter from 'components/MonthlyTimeFilter';
import LoadingSpinner from 'components/LoadingSpinner';
import Card from 'components/Cards/Card';
import Header from 'components/Header';
import Disclaimer from 'components/Disclaimer/Disclaimer';

import { selectCurrentAccesLevel, selectLocationsToShow } from 'store/selectors/distributionCenters.selectors';
import { getZonesWithIncidents } from 'store/actions/corporateDashboard.actions';
import { setBusinessUnit, setCluster, setSubzone, setZone } from 'store/actions/globalFilters.actions';
import { downloadIncidents, downloadCertificates, downloadAmbientalRisks, downloadTruckRoutinesExcel } from 'store/actions/distributionCenter.actions';
import { setMonthlyDropdownChange } from 'store/actions/timeFilter.actions';
import { selectSelectedBusinessUnit, selectSelectedLocationsSlug, selectUserAccesses } from 'store/selectors/globalFilters.selectors';
import { selectUser, selectUserLocation, selectUserType } from 'store/selectors/user.selectors';

import defaultRoutes from 'utils/defaultRoutes';
import { FILTER_ACCESS_LEVELS, TRANSLATIONS } from 'types/enums';
import { NameAndSlug } from 'types/interfaces';
import './style.scss';
import { userCanDownloadExcelSupply } from 'utils/shared/canUserAccessFeature';
import { selectTimezone } from 'store/selectors/timeFilter.selector';

const DistributionCenters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userType = useSelector(selectUserType);
  const userDetails = useSelector(selectUser);
  const timezone = useSelector(selectTimezone);
  const metaUserAccesses = useSelector(selectUserAccesses);

  const hasSupplyAccess = metaUserAccesses?.filter((metaUserAccess: any) => metaUserAccess.slug.includes('supply'));
  const hasLogisticAccess = metaUserAccesses?.filter((metaUserAccess: any) => !(metaUserAccess.slug.includes('supply')));
  const hasMixedAccesses = hasSupplyAccess?.length >= 1 && hasLogisticAccess?.length >= 1 && true;

  const user = useSelector((state: any) => state.get('user'))?.toJS();

  const arrayOfLocationToShow = useSelector(selectLocationsToShow);
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit);
  const accessLevel = useSelector(selectCurrentAccesLevel);
  const selectedLocations = useSelector(selectSelectedLocationsSlug);
  const location = useSelector(selectUserLocation);
  const isBuSupply = selectedLocations?.businessUnit?.includes('supply');

  const [currentMonth, setCurrentMonth] = useState('currentMonth');
  const [isUpdatingInfo, setUpdatingInfo] = useState(false);
  const updateZonesWithIncidents = async () => {
    setUpdatingInfo(true);
    await dispatch(getZonesWithIncidents());
    setUpdatingInfo(false);
  };
  useEffect(() => {
    dispatch(setMonthlyDropdownChange('currentMonth'));
  }, [dispatch]);

  useEffect(() => {
    updateZonesWithIncidents();
    // eslint-disable-next-line
  }, [dispatch, currentMonth]);

  const routes = defaultRoutes(t, userType, location, userDetails);

  let downloadDropdownContent = [
    { label: t(TRANSLATIONS.INCIDENTS), value: TRANSLATIONS.INCIDENTS },
    { label: upperFirst(t(TRANSLATIONS.CERTIFICATES)), value: TRANSLATIONS.CERTIFICATES },
    { label: 'Rutinas flota', value: 'Rutinas flota' },
    // { label: upperFirst(t(TRANSLATIONS.BOTH)), value: TRANSLATIONS.BOTH },
  ];

  if (isBuSupply
    || (userCanDownloadExcelSupply(user?.roleDetails?.slug, user?.features))
  ) {
    downloadDropdownContent = [
      { label: t(TRANSLATIONS.INCIDENTS), value: TRANSLATIONS.INCIDENTS },
      { label: upperFirst(t(TRANSLATIONS.CERTIFICATES)), value: TRANSLATIONS.CERTIFICATES },
      { label: upperFirst(t(TRANSLATIONS.AMBIENTAL_RISKS)), value: TRANSLATIONS.AMBIENTAL_RISKS },
    ];
  }

  const handleCardClick = ({ name, slug }: NameAndSlug, e: any) => {
    if (e.target.id === 'card') {
      switch (accessLevel) {
        case FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER:
          navigate(`/distribution-centers/${slug}`);
          break;
        case FILTER_ACCESS_LEVELS.CLUSTER:
          dispatch(setCluster({ name, slug }));
          break;
        case FILTER_ACCESS_LEVELS.SUBZONE:
          dispatch(setSubzone({ name, slug }));
          break;
        case FILTER_ACCESS_LEVELS.ZONE:
          dispatch(setZone({ name, slug }));
          break;
        case FILTER_ACCESS_LEVELS.BUSINESS_UNIT:
          dispatch(setBusinessUnit({ name, slug }));
          break;
        default:
          break;
      }
    }
  };

  const downloadReport = (dowloadOption: string,
    locationSlug: string,
    level?: string,
    locationType?: string) => {
    const param = `${level}=${locationSlug}`;
    const isFactory = locationType === 'FACTORY'
    || selectedLocations?.businessUnit?.includes('supply')
    || (!hasMixedAccesses && hasSupplyAccess?.length >= 1);

    switch (dowloadOption) {
      case TRANSLATIONS.BOTH:
        dispatch(downloadIncidents(param, isFactory));
        dispatch(downloadCertificates(timezone, param));
        break;
      case TRANSLATIONS.INCIDENTS:
        dispatch(downloadIncidents(param, isFactory));
        break;
      case TRANSLATIONS.CERTIFICATES:
        dispatch(downloadCertificates(timezone, param));
        break;
      case TRANSLATIONS.AMBIENTAL_RISKS:
        dispatch(downloadAmbientalRisks(param));
        break;
      case 'Rutinas flota':
        dispatch(downloadTruckRoutinesExcel(param));
        break;
      default:
        break;
    }
  };

  const handleDownloadOptionChange = ({ target: { value } }: any) => {
    // downloadReport(value, null, accessLevel);
    if (accessLevel === FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER) {
      if (selectedLocations.cluster === '' || selectedLocations.cluster === undefined) {
        return downloadReport(
          value, selectedLocations.subzone, FILTER_ACCESS_LEVELS.SUBZONE,
        );
      }
      return downloadReport(
        value, selectedLocations.cluster, FILTER_ACCESS_LEVELS.CLUSTER,
      );
    }
    if (accessLevel === FILTER_ACCESS_LEVELS.CLUSTER) {
      return downloadReport(
        value, selectedLocations.subzone, FILTER_ACCESS_LEVELS.SUBZONE,
      );
    }
    if (accessLevel === FILTER_ACCESS_LEVELS.SUBZONE) {
      return downloadReport(
        value, selectedLocations.zone, FILTER_ACCESS_LEVELS.ZONE,
      );
    }
    if (accessLevel === FILTER_ACCESS_LEVELS.ZONE) {
      return downloadReport(
        value, selectedLocations.businessUnit, FILTER_ACCESS_LEVELS.BUSINESS_UNIT,
      );
    }
    downloadReport(value, '', '');
  };

  const handleMonthChange = (value: 'typeOf MonthlyOptions') => {
    setCurrentMonth(value);
    dispatch(setMonthlyDropdownChange(value));
  };

  return (
    <>
      <Header />
      <NavigationDrawerWithSideMenu {...{ routes, openDrawer: true }} />
      {arrayOfLocationToShow ? (
        <div className="distribution-centers">
          <div>
            <div className="distribution-centers__top-menu">
              <div className="distribution-centers__top-menu__buttons">
                {(!!selectedBusinessUnit?.slug && (!hasMixedAccesses)
                  || (hasMixedAccesses && selectedLocations?.businessUnit)) ? (
                    <SelectDropdown
                      name="Download Dropdown"
                      onChange={handleDownloadOptionChange}
                      value=""
                      dropDownClassName="dropdown-constraints"
                      dropdownContent={downloadDropdownContent}
                      className="download-dropdown"
                      placeholder={`${upperFirst(t(TRANSLATIONS.DOWNLOAD_ALL))}`}
                      bgColor="transparent"
                    />
                  ) : ''}
                <MonthlyTimeFilter
                  onChange={handleMonthChange}
                  currentValue={currentMonth}
                  t={t}
                  bgColor="transparent"
                />
                { isUpdatingInfo && (<LoadingSpinner text=" " height={25} />)}
              </div>
            </div>
            <div className="distribution-centers__list">
              {arrayOfLocationToShow?.list?.map((item: any) => {
                return (
                  <Card
                    item={item}
                    handleClick={handleCardClick}
                    key={item.slug}
                    downloadReport={downloadReport}
                  />
                );
              })}
            </div>
          </div>
          { arrayOfLocationToShow?.list?.size === 0 && (
            <p
              style={{ textAlign: 'center' }}
            >
              {t(TRANSLATIONS.YOU_DONT_HAVE_ANY_LOCATION_ASSIGNED_PLEASE_CONTACT_SUPPORT)}
            </p>
          )}
          <Disclaimer message={TRANSLATIONS.USER_ACCESS_DISCLAIMER} />
        </div>
      ) : (
        <div className="distribution-centers__loader">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default DistributionCenters;
