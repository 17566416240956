import React, { useEffect, useContext, useMemo } from 'react';
import { TRANSLATIONS, UserTypes } from 'types/enums';
import { General_User_Roles, Location_Type, Logistics_User_Roles, Supply_User_Roles } from 'generated/graphql';
import RoutinesSelector from '../RoutinesTab/routinesSelector';
import RoutinesViewer from '../RoutinesTab/routinesViewer';
import { UserManagementContext } from '../../context';
import request from 'utils/request';
import { useTranslation } from 'react-i18next';
import { fromJS } from 'immutable';



const RoutinesTab = (props:any) => {
  const { t } = useTranslation();
  const state = useContext(UserManagementContext);
  const { isDeletedUser } = props;
  const {
    originalUser,
    isEditing,
    editingUser,
    setEditingUser,
    setIsLoading,
    setOriginalUser,
    setUserAvailableRoles,
  } = state;
  const userData = state.user;

  const distributionCenterType = originalUser?.get('general')?.get('distributionCenter')?.get('type');
  const isDistributionCenter = distributionCenterType
      === Location_Type.DistributionCenter;

  const routinesPermissions = originalUser
    ?.get('permissions')
    ?.get('routines')
    ?.toJS();

  const editableRoutinesPermissions = editingUser
    ?.get('permissions')
    ?.get('routines')
    ?.toJS();

  const permissionsOptions = useMemo(() => {
    if (isDistributionCenter) {
      return [
        {
          id: 1,
          label: `${t(TRANSLATIONS.CERTIFICATES)}`,
          type: UserTypes.CertificatesOnly,
          roleSlug: General_User_Roles.CertificatesOnly as string,
        },
        {
          id: 2,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(
            TRANSLATIONS.INCIDENTS,
          )}, ${t(TRANSLATIONS.ACTION_LOGS)}`,
          type: UserTypes.ReporterOnlyDPO,
          roleSlug: Logistics_User_Roles.ReporterOnlyDpo as string,
        },
        {
          id: 3,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.ACTION_LOGS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
          type: UserTypes.CorporateDPO,
          roleSlug: Logistics_User_Roles.CorporateDpo as string,
        },
        {
          id: 4,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(
            TRANSLATIONS.CLOSE,
          )} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.ACTION_LOGS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
          type: UserTypes.DistributionCenterManagerDPO,
          roleSlug: Logistics_User_Roles.DistributionCenterManagerDpo as string,
        },
        {
          id: 5,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(
            TRANSLATIONS.CLOSE,
          )}, ${t(TRANSLATIONS.ASSIGN)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(
            TRANSLATIONS.ACTION_LOGS,
          )}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
          type: UserTypes.SubzoneGeneralManagerDPO,
          roleSlug: Logistics_User_Roles.SubzoneGeneralManagerDpo as string,
        },
      ];
    }
    return [
      {
        id: 1,
        label: `${t(TRANSLATIONS.CERTIFICATES)}`,
        type: UserTypes.CertificatesOnly,
        roleSlug: General_User_Roles.CertificatesOnly as string,
      },
      {
        id: 2,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(
          TRANSLATIONS.INCIDENTS,
        )}, ${t(TRANSLATIONS.AMBIENTAL_RISKS)}`,
        type: UserTypes.ReporterOnlySupply,
        roleSlug: Supply_User_Roles.ReporterOnly as string,
      },
      {
        id: 3,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
        type: UserTypes.CorporateSupply,
        roleSlug: Supply_User_Roles.CorporateSupply as string,
      },
      {
        id: 4,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(
          TRANSLATIONS.CLOSE,
        )} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.AMBIENTAL_RISKS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
        type: UserTypes.DistributionCenterManagerSupply,
        roleSlug: Supply_User_Roles.EjecutorSafetyEnvironment as string,
      },
      {
        id: 5,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(
          TRANSLATIONS.CLOSE,
        )}, ${t(TRANSLATIONS.ASSIGN)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(
          TRANSLATIONS.AMBIENTAL_RISKS,
        )}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
        type: UserTypes.SubzoneGeneralManagerSupply,
        roleSlug: Supply_User_Roles.AssignerSafetyEnvironment as string,
      },
      {
        id: 6,
        label: t(TRANSLATIONS.PRODUCTION_PERFORMANCE_TRACKING),
        type: UserTypes.BreweryViewer,
        roleSlug: Supply_User_Roles.PlantManager as string,
      },
    ];
  }, [isDistributionCenter, t]);

  const loadPermissions = async () => {
    let tempObj = {} as any;
    let availablePermissons;
    try {
      setIsLoading(true);
      const userRequest = request.get(`/api/v1/users/${encodeURIComponent(userData.slug)}/info?isUserDeleted=${isDeletedUser}`).then(res => {
        const typeFromApi = permissionsOptions.find(
          ({ type }) => res.info?.type === type,
        );

        const roleFromApi = res?.info?.userRole || {};

        const obj = {
          permissions: {
            type: (
              !roleFromApi
              && typeFromApi
            ) ? typeFromApi
              : (
                roleFromApi
                || permissionsOptions[1]
              ),
            roleId: (res?.info?.roleId),
            roleSlug: (res?.info?.userRole?.slug),
            role: roleFromApi,
            ...tempObj.permissions,
          },
        };
        tempObj = { ...tempObj, ...obj };
      });
      const permissionRequest = request.get(`/api/v1/users/${encodeURIComponent(userData.slug)}/permissions?isUserDeleted=${isDeletedUser}`).then(
        res => {
          const routinesGroup = {} as any;
          res.permissions.forEach((routine: any) => {
            if (routinesGroup[routine.pillar]) {
              routinesGroup[routine.pillar].routines = (
                routinesGroup[routine.pillar].routines.concat(routine)
              );
            } else {
              routinesGroup[routine.pillar] = {
                routines: [].concat(routine),
                selected: true,
              };
            }
          });
          tempObj = {
            ...tempObj,
            permissions: {
              routines: {
                ...routinesGroup,
              },
              ...tempObj.permissions,
            },
          };
        },
      );
      const availablePermissionesRequest = request.get('/api/v1/users-available-roles').then(
        res => {
          availablePermissons = res.roles;
        },
      );
      await Promise.all([userRequest, permissionRequest, availablePermissionesRequest]);
      setOriginalUser(originalUser.merge(fromJS(tempObj)));
      if (!editingUser?.getIn(['permissions', 'routines'])?.size) {
        setEditingUser(editingUser.merge(fromJS(tempObj)));
      }
      setUserAvailableRoles(availablePermissons);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadPermissions();
    // eslint-disable-next-line
  }, []);


  return (
    <div>
    {isEditing ? (
      <div className="user-info__bottom">
        <RoutinesSelector
          routinesByPillars={editableRoutinesPermissions || {}}
          setFormData={setEditingUser}
        />
      </div>
    ) : (
      <div className="user-info__bottom">
        <RoutinesViewer routinesByPillars={routinesPermissions} />
      </div>
    )}
  </div>
  );
};

export default RoutinesTab;
