import { fromJS } from 'immutable';
import React, { useState, useEffect } from 'react';

export type UserPermissionType = { name: string, id: number, slug: string };

export const UserManagementContext = React.createContext<any>({});
const UserManagementContextProvider = (props: any) => {
  const [user, setUser] = useState<any>({});
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accessLocationsIds, setAccessLocationsIds] = useState<any[]>([]);
  const [isUMDrawerVisible, setUMDrawerVisibility] = useState(false);
  const [userAvailableRoles, setUserAvailableRoles] = useState<UserPermissionType[]>([]);

  const [originalUser, setOriginalUser] = useState(
    fromJS({
      profile: {},
      general: {},
      permissions: {},
      locationAccess: {},
    }),
  );

  const [editingUser, setEditingUser] = useState(
    fromJS({
      profile: {},
      general: {},
      permissions: {},
      locationAccess: {},
    }),
  );

  function clearContext() {
    setOriginalUser(fromJS({
      profile: {},
      general: {},
      permissions: {},
      locationAccess: {},
    }));
    setEditingUser(fromJS({
      profile: {},
      general: {},
      permissions: {},
      locationAccess: {},
    }));
    setUMDrawerVisibility(false);
    setAccessLocationsIds([]);
    setIsEditing(false);
  }

  const setUserDetails = (userDetails: any) => {
    setUser(userDetails);
  };

  useEffect(() => {
    if (isEditing) {
      const showDeletedUsers = originalUser.getIn(['general', 'roleSlug']) === 'deleted_user';
      if (!showDeletedUsers) {
        setEditingUser(fromJS(originalUser.toJS()));
      }
    }
    setIsLoading(false);
  // eslint-disable-next-line
  }, [isEditing]);

  useEffect(() => {
    if (!isUMDrawerVisible) {
      setIsEditing(false);
    }
  }, [isUMDrawerVisible]);

  return (
    <UserManagementContext.Provider
      value={{
        user,
        setUserDetails,
        isLoading,
        setIsLoading,
        setIsEditing,
        isEditing,
        originalUser,
        setOriginalUser,
        editingUser,
        setEditingUser,
        isUMDrawerVisible,
        setUMDrawerVisibility,
        accessLocationsIds,
        setAccessLocationsIds,
        clearContext,
        userAvailableRoles,
        setUserAvailableRoles,
      }}
    >
      {props.children}
    </UserManagementContext.Provider>
  );
};
export default UserManagementContextProvider;
