import CheckBoxWithIcon from 'components/CheckBoxWithIcon';
import React from 'react';
import { capitalizeFirst } from 'utils/stringHelpers';
import { withStyles } from '@mui/styles';
import { Checkbox, CheckboxProps } from '@mui/material';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import ImageLinks from 'utils/ImageLinks';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';

const { emptyRadioUnchecked, yellowRadioChecked } = ImageLinks;

interface RoutinesByPillars {
  selected: boolean;
  routines: {
    slug: string;
    name: string;
    granted: boolean;
    editable: boolean;
  }[];
}

interface RoutinesSelectorProps {
  routinesByPillars: {
    [key: string]: RoutinesByPillars;
  };
}

function getCountOfGrantedRoutines(routinesByPillars: RoutinesByPillars) {
  return routinesByPillars.routines.filter(routine => !!routine.granted).length;
}

function getCountOfTotalRoutines(routinesByPillars: RoutinesByPillars) {
  return routinesByPillars.routines.length;
}

const RoutinesViewer: React.FC<RoutinesSelectorProps> = props => {
  const { t } = useTranslation();
  const OrangeCheckbox = withStyles({
    root: {
      '&$checked': {
        color: '#EFBE3F',
      },
    },
    checked: {},
  })((MUIRadioProps: CheckboxProps) => <Checkbox color="default" {...MUIRadioProps} />);

  const routinesGroupedByPillar = props.routinesByPillars || {};

  const pillarKeys = Object.keys(routinesGroupedByPillar);

  const hasRoutines = pillarKeys.some(pillar => {
    if (!routinesGroupedByPillar[pillar] || !routinesGroupedByPillar[pillar].routines) {
      return false;
    }
    return routinesGroupedByPillar[pillar].selected &&
           routinesGroupedByPillar[pillar].routines.some(routine => routine.granted);
  });

  if (!hasRoutines) {
    return (
          <div>
            <span>{t(TRANSLATIONS.NO_ROUTINES_FOR_USER)}</span>
          </div>
    );
  }

  return (
    <>
      <div className="row select_all_container">
        <div className="routineList">
          {pillarKeys.map((pillar: string) => {
            if (!routinesGroupedByPillar[pillar] || !routinesGroupedByPillar[pillar].routines) {
              return null;
            }
            const { routines } = routinesGroupedByPillar[pillar];
            if (routinesGroupedByPillar[pillar].selected) {
              const grantedRoutines = routines.filter(r => r.granted);
              if (grantedRoutines.length < 1) {
                return null;
              }
              return (
                <div className="routineContainer" key={pillar}>
                  <h4>
                    {capitalizeFirst(pillar)} (
                    {getCountOfGrantedRoutines(routinesGroupedByPillar[pillar])} /{' '}
                    {getCountOfTotalRoutines(routinesGroupedByPillar[pillar])})
                  </h4>
                  {grantedRoutines.map(routine => (
                    <CheckBoxWithIcon
                      deSelectedIcon={emptyRadioUnchecked}
                      selectedIcon={yellowRadioChecked}
                      selected={routine.granted}
                      label={routine.name}
                      key={routine.slug}
                      icon={<OrangeCheckbox
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={routine.granted}
                      />}
                    />
                  ))}
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </>
  );
};

export default RoutinesViewer;
