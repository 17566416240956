import React from 'react';
import ImageLinks from 'utils/ImageLinks';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { esES, enUS } from '@mui/x-date-pickers/locales';
import { differenceInDays } from 'date-fns';
import { getTranslatedDateTimeValue } from 'utils/dateHelpers';
import i18next from 'i18n';
import { ACTION_LOG_STATUS, ACTION_LOG_SUMMARY_MODAL_STATUS, TRANSLATIONS } from 'types/enums';
import './SummaryModalDatePickerStyle.scss';
import { useTranslation } from 'react-i18next';
import * as styles from './SummaryModalDatePickerStyles';

const DateLocale = i18next.language?.indexOf('en') === 0 ? enUS.components.MuiLocalizationProvider.defaultProps.localeText : esES.components.MuiLocalizationProvider.defaultProps.localeText;

type SummaryModalDatePickerProps = {
  primaryDate: Date;
  text: string;
  secondaryDate: Date;
  modalStatus: ACTION_LOG_SUMMARY_MODAL_STATUS;
  status: ACTION_LOG_STATUS;
  onDateChange: (date: any) => void;
  showTimePicker?: boolean;
  minDate?: Date;
  isEndDate?: boolean
};

function SummaryModalDatePicker(props: SummaryModalDatePickerProps) {
  const { t } = useTranslation();
  const lng = useTranslation()[1].language;
  const {
    primaryDate,
    text,
    modalStatus,
    onDateChange,
    showTimePicker = false,
    minDate,
    status,
    isEndDate,
  } = props;
  function handleTimePicker(date: any) {
    const dateWithTimeUpdate = new Date(primaryDate);
    dateWithTimeUpdate.setHours(date?.getHours());
    dateWithTimeUpdate.setMinutes(date?.getMinutes());
    dateWithTimeUpdate.setSeconds(date?.getSeconds());
    onDateChange(dateWithTimeUpdate);
  }
  const isDueDateArriving: boolean = !!(
    isEndDate
    && status !== ACTION_LOG_STATUS.COMPLETED
    && differenceInDays(
      (new Date(primaryDate)),
      (new Date()),
    ) < 3);
  return (
    <>
      <div className="calender_section">
        {modalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY ? (
          <><img src={ImageLinks.greenCalender} alt="calendar" />
            <p className="end_date_text">
              {getTranslatedDateTimeValue(
                primaryDate,
                'dd MMM',
              )}
            </p>
          </>
        ) : (
          <div className="date_time_picker_container">
            <div>
              <div className="date_time_title">
                {isEndDate ? t(TRANSLATIONS.CLOSING_DATE) : t(TRANSLATIONS.START_DATE)}
              </div>
              <LocalizationProvider dateAdapter={AdapterDateFns} localeText={DateLocale}>
                <DatePicker
                  format="MM/dd/yyyy"
                  minDate={minDate}
                  value={primaryDate}
                  onChange={onDateChange}
                  sx={styles.datePicker}
                />
              </LocalizationProvider>
            </div>
            {showTimePicker && (
              <LocalizationProvider dateAdapter={AdapterDateFns} localeText={DateLocale}>
                <TimePicker
                  value={primaryDate}
                  onChange={handleTimePicker}
                  sx={styles.timePicker}
                />
              </LocalizationProvider>
            )}
          </div>
        )}
      </div>
      {modalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY && (
      <>
        <p className="create_date">{text} </p>
        <p className={`time_passed ${isDueDateArriving ? 'alert_end_date' : ''}`}>
          {t(TRANSLATIONS.SINCE)} {lng === 'es' ? 'el' : ''}{' '}
          {getTranslatedDateTimeValue(primaryDate, 'eee dd')} {lng === 'es' ? 'de' : 'of'}{' '}
          {getTranslatedDateTimeValue(primaryDate, 'MMM')}
        </p>
      </>
      )}
    </>
  );
}

export default SummaryModalDatePicker;
