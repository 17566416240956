import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TRANSLATIONS, UserTypes } from 'types/enums';
import LinkDropdown from 'components/Dropdown/LinkDropdown';

import { ReactComponent as CogIcon } from 'assets/icons/cog.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/log-out.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/icon-upload.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';

import './Settings.scss';
import { useSelector } from 'react-redux';
import { userIsBreweryPlantManager, userHasWebAccess, userOnlyHasAccessToReportSIOs } from 'utils/shared/canUserAccessFeature';

type Props = {
  userType?: string;
  fromSubmitSuccessful?: boolean;
  fromFormSubmission?: boolean;
};

const Settings: FC<Props> = (props: Props) => {
  const { userType, fromFormSubmission } = props;
  const [isClicked, setClicked] = useState(false);
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.get('user'))?.toJS();
  /**
   * Ensure every item of this array is fully lower cased.
   */
  const allowedEmails = ['ana.velazquez@ab-inbev.com', 'erika.perezs@ab-inbev.com', 'juan.leon.N@ab-inbev.com', 'francisco.juarezg@ab-inbev.com', 'info@arvolution.com'];

  function onVisibleChange(isVisible: boolean) {
    setClicked(isVisible);
  }

  const settingsItems = [];

  if (user) {
    const uiAvatarImage = user?.picture?.length
      ? user.picture
      : encodeURI(
        `https://ui-avatars.com/api/?name=${user?.name}&color=ff0000`,
      );
    settingsItems.push({
      url: '',
      target: '',
      label: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <img
            src={uiAvatarImage}
            style={{ width: 30, height: 30, borderRadius: 20 }}
            alt="uiAvatarImage"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: 12,
              marginLeft: 10,
              overflowWrap: 'anywhere',
            }}
          >
            <b>{user?.name}</b>
            <i>{user?.email}</i>
          </div>
        </div>
      ),
      insertSectionDivider: true,
    });
  }

  if (userIsBreweryPlantManager(user?.roleDetails?.slug, user?.type)) {
    settingsItems.push({
      url: '/upload/daily-production-plannings',
      label: (
        <span style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
        }}
        >
          {t(TRANSLATIONS.UPLOAD_REGISTRATION)}
        </span>
      ),
    });

    settingsItems.push({
      url: '/api/v1/daily-production-plannings/excel',
      target: '_blank',
      label: (
        <span style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
        }}
        >
          Descargar plan diarios
        </span>
      ),
    });
  }

  if (allowedEmails.includes(user?.email?.toLowerCase())) {
    settingsItems.push({
      url: '/file-upload',
      label: (
        <span style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          minWidth: '192px',
        }}
        >
          <UploadIcon />
          {t(TRANSLATIONS.FILE_UPLOAD)}
        </span>
      ),
    });
  }

  if (
    fromFormSubmission
    && !userOnlyHasAccessToReportSIOs(
      user?.features,
      user,
    )
    && userHasWebAccess(user?.roleDetails?.slug, user?.features, userType as UserTypes)) {
    settingsItems.push({
      url: '/',
      label: (
        <span style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          minWidth: '192px',
        }}
        >
          <DashboardIcon />
          {t(TRANSLATIONS.GO_TO_DASHBOARD)}
        </span>
      ),
    });
  }

  settingsItems.push({
    url: '/logout',
    label: (
      <span style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: '192px',
      }}
      >
        <LogoutIcon />
        {t(TRANSLATIONS.USER_LOGOUT)}
      </span>
    ),
  });

  return (
    <div className="settings">
      <LinkDropdown
        dropdownContent={settingsItems}
        className={`link-dropdown ${isClicked ? 'clicked' : ''}`}
        dropdownContentPositioning={{ right: -10 }}
        onVisibleChange={onVisibleChange}
      >
        <CogIcon />
      </LinkDropdown>
    </div>
  );
};

export default Settings;
