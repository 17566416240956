import { UserMap } from 'types/interfaces';

export const capitalizeFirst = (word: string) => {
  if (!word || !word.length) return '';
  return word.charAt(0).toUpperCase() + word.toLowerCase().substring(1);
};

export function userNameExtractor(user: UserMap) {
  return user.get('employeeNumber') ? `${user.get('name')} **${user.get('employeeNumber').slice(-2)}` : `${user.get('name')}`;
}

export function getShortMonth(month: string) {
  return month.substring(0, 3);
}

export function capitalize(input: string) {
  return input.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}

export function hasMoreThanThreeConsecutiveNumbers(value: any) {
  let count = 1;

  for (let i = 0; i < value.length - 1; i++) {
    if (!isNaN(value[i]) && value[i] !== ' ' && !isNaN(value[i + 1]) && value[i + 1] !== ' ') {
      let current = Number(value[i]);
      let next = Number(value[i + 1]);

      if (next === current + 1 || next === current - 1) {
        count = count + 1;
        if (count > 3) return true;
      } else {
        count = 1;
      }
    } else {
      count = 1;
    }
  }

  return false;
}
