import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import SubmitSuccessful from 'features/external-users/AnonymousComplaint/SubmitSuccessful';
import TruckFormSuccess from 'features/external-users/AnonymousTruckComplaint/TruckFormSuccess';
import LoadingSpinner from 'components/LoadingSpinner';
import ResetPassword from 'features/internal-users/user/ForgotPassword/ResetPassword';
import AnonymousTruckScann from 'features/external-users/AnonymousTruckScan';
import HRProfileInformation from 'features/doctor/HRProfileInformation';
import ExtendedView from 'features/safety-academy/ExtendedView/ExtendedView';
import SafetyAcademyHome from 'features/safety-academy/SafetyAcademyHome';
import FileUploadHome from 'features/file-upload/FileUploadHome';
import PostUpload from 'features/file-upload/PostUpload/PostUpload';
import Boards from 'features/action-logs/Boards';
import PatientStatusExternalLink from 'features/PatientStatusExternalLink';
import FileUploadAnalytics from 'features/file-upload/FileUploadAnalytics';
import RecoverPassword from 'features/internal-users/user/RecoverPassword/RecoverPassword';
import useReloadWebsite from 'utils/hooks/useReloadWebsite';
import LocationAreasConfig from 'features/internal-users/DistCenterWrapper/LocationAreasConfig/LocationAreasConfig';
import MfaVerification from 'features/internal-users/user/Mfa/MfaVerification';
import LinkAccounts from 'features/internal-users/user/LinkAccounts';

import { useSelector } from 'react-redux';
import { selectUserType, selectUser } from 'store/selectors/user.selectors';
import { userOnlyHasAccessToSearchUserScreen, canUserAccessRoadSafetyResourcesSideBar, canUserAccessRoadSafetySideBar, canUserAccessCriticalRoutesSideBar, canUserAccessRoadSafetyDriverSideBar, canAccessDailyAgenda } from 'utils/shared/canUserAccessFeature';
import UserManagement from 'features/internal-users/user/UsersIndexN/UserManagement';
import OWD from 'features/owd';
import RegisterNewAccount from 'features/internal-users/user/RegisterNewAccount/RegisterNewAccount';
import PDT from 'features/pdt';
import DPOAcademy from 'features/dpo-academy';
import IncidentsAssign from 'features/incidents/incidents-assign';
import Resources from 'features/RoadSafety/Resources';
import Drivers from 'features/RoadSafety/Drivers';
import CriticalPaths from 'features/RoadSafety/CriticalPaths';
import RouteDetails from 'features/RoadSafety/RouteDetails';
// import Planning from 'features/RoadSafety/Planning';
import IncidentsClose from 'features/incidents/incidents-close';
import AuthWrapper from './features/internal-users/user/AuthWrapper';
import Login from './features/internal-users/user/Login';
import Register from './features/internal-users/user/Register';
import PrivacyPolicy from './features/privacy-policy/PrivacyPolicy';
import DistributionCenters from './features/internal-users/DistributionCenters/DistributionCenters';
import DistributionCenterInfo from './features/internal-users/DistCenterWrapper/DistributionCenter';
import SafetyDashboard from './features/internal-users/SafetyDashboard';
import UsersIndex from './features/internal-users/user/UsersIndex';
import UsersIndexN from './features/internal-users/user/UsersIndexN';

import UserManagementDashboard from './features/users-management/UserManagementDashboard';
import Logout from './features/Logout';
import BackendEdit from './features/internal-users/BackendEdit';
import AnalyticsWrapper from './features/analyticsWrapper/AnalyticsWrapper';
import DailyProductionPlanningUpload from './features/internal-users/DailyProductionPlanningUpload';
import AddSupplyRoutine from './features/internal-users/AddSupplyRoutine';
import SupplyRoutineMobile from './features/internal-users/SupplyRoutineMobile';
import AnonymousComplaint from './features/external-users/AnonymousComplaint';
import AnonymousTruckComplaint from './features/external-users/AnonymousTruckComplaint';
import AnonymousSellBrands from './features/external-users/AnonymousSellBrands';
import Routines from './features/external-users/DeepLinkingApp/Routines/Routines';
import DailyAgenda from './features/DailyAgenda/DailyAgendaDashboard';
import EditDailyAgenda from './features/DailyAgenda/EditDailyAgenda';
import ViewDailyAgenda from './features/DailyAgenda/ViewDailyAgenda';

export default () => {
  const userType = useSelector(selectUserType);
  const userDetails = useSelector(selectUser);
  const canAccessScheduleScreen = useSelector((state: any) => state.getIn(['dailyAgenda', 'canAccessScheduleScreen']));

  return (
    <Router>
      <Suspense
        fallback={(
          <LoadingSpinner
            style={{
              position: 'fixed',
              top: '50%',
              right: '50%',
              marginLeft: 30,
              marginBottom: 30,
            }}
          />
        )}
      >
        {useReloadWebsite()}
        <Routes>
          <Route path="/register/:token" element={<Register />} />
          <Route path="/recover-password/:token" element={<RecoverPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/register-account" element={<RegisterNewAccount />} />
          <Route path="/tc/thank-you" element={<TruckFormSuccess />} />
          <Route path="/tc" element={<AnonymousTruckComplaint />} />
          <Route path="/sell-brands" element={<AnonymousSellBrands />} />
          <Route path="/ts" element={<AnonymousTruckScann />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/app/routines/:slug" element={<Routines />} />
          <Route path="/academy/extended-view/:slug" element={<ExtendedView />} />
          <Route path="/health-status/:userSlug/:userName" element={<PatientStatusExternalLink />} />
          <Route path="/MfaVerification" element={<MfaVerification />} />
          <Route path="/LinkAccounts" element={<LinkAccounts />} />

          {userOnlyHasAccessToSearchUserScreen(userType) && (
            <Route path="/users-blocked" element={<AuthWrapper component={UsersIndexN} />} />
          )}

          <Route path="/locations/:locationSlug/areas-subareas" element={<AuthWrapper component={LocationAreasConfig} />} />
          <Route path="/distribution-centers/:distCenter/users" element={<AuthWrapper component={UserManagementDashboard} />} />
          <Route path="/distribution-centers/:distCenter/users-access" element={<AuthWrapper component={UsersIndex} />} />
          <Route path="/distribution-centers/:distCenter/s" element={<AuthWrapper component={SafetyDashboard} />} />
          <Route path="/distribution-centers/:distCenter" element={<AuthWrapper component={DistributionCenterInfo} />} />
          <Route path="/distribution-centers" element={<AuthWrapper component={DistributionCenters} />} />
          <Route path="/upload/daily-production-plannings" element={<AuthWrapper component={DailyProductionPlanningUpload} />} />
          <Route path="/upload/daily-routine" element={<AuthWrapper component={AddSupplyRoutine} />} />
          <Route path="/mobile/daily-routine" element={<AuthWrapper component={SupplyRoutineMobile} />} />
          <Route path="/users" element={<AuthWrapper component={UsersIndex} />} />
          <Route path="/users-blocked" element={<AuthWrapper component={UsersIndexN} />} />

          <Route path="/backend-edit" element={<AuthWrapper component={BackendEdit} />} />
          <Route path="/doctor/hr-profile-information" element={<AuthWrapper component={HRProfileInformation} />} />
          <Route path="/safety-academy" element={<AuthWrapper component={SafetyAcademyHome} />} />
          <Route path="/dpo-academy" element={<AuthWrapper component={DPOAcademy} />} />
          <Route path="/file-upload" element={<AuthWrapper component={FileUploadHome} />} />
          <Route path="/file-upload/analytics" element={<AuthWrapper component={FileUploadAnalytics} />} />
          <Route path="/file-upload/academy" element={<AuthWrapper component={PostUpload} />} />
          <Route path="/ac/thank-you" element={<AuthWrapper component={SubmitSuccessful} />} />
          <Route path="/ac" element={<AuthWrapper component={AnonymousComplaint} />} />
          <Route path="/action-log/board" element={<AuthWrapper component={Boards} />} />
          <Route path="/owd" element={<AuthWrapper component={OWD} />} />
          <Route path="/pdt" element={<AuthWrapper component={PDT} />} />
          <Route path="/user-management-drawer" element={<AuthWrapper component={UserManagement} />} />
          <Route path="/incidents/assign" element={<AuthWrapper component={IncidentsAssign} />} />
          <Route path="/incidents/close" element={<AuthWrapper component={IncidentsClose} />} />
          {canAccessDailyAgenda(userDetails?.get('position'), userDetails?.get('features')) && (
            <>
              <Route path="/dailyAgenda" element={<AuthWrapper component={DailyAgenda} />} />
              {canAccessScheduleScreen && (
                <>
                  <Route path="/editDailyAgenda" element={<AuthWrapper component={EditDailyAgenda} />} />
                  <Route path="/viewDailyAgenda" element={<AuthWrapper component={ViewDailyAgenda} />} />
                </>
              )}
            </>
          )}

          {canUserAccessRoadSafetySideBar(userDetails?.get('features')) && canUserAccessRoadSafetyResourcesSideBar(userDetails?.get('features')) && (
            <Route path="/road-safety/resources" element={<AuthWrapper component={Resources} />} />
          )}
          {canUserAccessRoadSafetySideBar(userDetails?.get('features')) && canUserAccessCriticalRoutesSideBar(userDetails?.get('features')) && (
            <>
              <Route path="/road-safety/critical-routes" element={<AuthWrapper component={CriticalPaths} />} />
              <Route path="/road-safety/route-details" element={<AuthWrapper component={RouteDetails} />} />
            </>
          )}
          {canUserAccessRoadSafetySideBar(userDetails?.get('features')) && canUserAccessRoadSafetyDriverSideBar(userDetails?.get('features')) && (
            <>
              <Route path="/road-safety/drivers" element={<AuthWrapper component={Drivers} />} />
              {/* <Route path="/road-safety/planning" element={<AuthWrapper component={Planning} />} /> */}
            </>
          )}

          <Route path="/*" element={<AuthWrapper component={AnalyticsWrapper} />} />
        </Routes>
      </Suspense>
    </Router>
  );
};
