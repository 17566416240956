import CheckBoxWithIcon from 'components/CheckBoxWithIcon';
import { fromJS } from 'immutable';
import { cloneDeep } from 'lodash';
import React, { useState, useEffect } from 'react';
import imageLinks from 'utils/ImageLinks';
import { capitalizeFirst } from 'utils/stringHelpers';
import { withStyles } from '@mui/styles';
import { Checkbox, CheckboxProps } from '@mui/material';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';

interface RoutinesByPillars {
  selected: boolean;
  routines: {
    slug: string;
    name: string;
    granted: boolean;
    editable: boolean;
  }[];
}

interface RoutinesSelectorProps {
  setFormData: Function;
  routinesByPillars: {
    [key: string]: RoutinesByPillars;
  };
}

function getCountOfGrantedRoutines(routinesByPillars: RoutinesByPillars) {
  return routinesByPillars.routines.filter(routine => !!routine.granted).length;
}

const RoutinesSelector: React.FC<RoutinesSelectorProps> = props => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [activePillar, setActivePillar] = useState<string | null>(null);

  const OrangeCheckbox = withStyles({
    root: {
      '&$checked': {
        color: '#EFBE3F',
      },
    },
    checked: {},
  })((MUIRadioProps: CheckboxProps) => <Checkbox color="default" {...MUIRadioProps} />);

  const { routinesByPillars: routinesGroupedByPillar } = props;
  const { emptyRadioUnchecked, yellowRadioChecked } = imageLinks;

  const { setFormData } = props;

  useEffect(() => {
    setFormData((formData: any) => {
      let updatedFormData = formData;
      Object.keys(routinesGroupedByPillar).forEach((pillar: string) => {
        updatedFormData = updatedFormData.setIn(
          ['permissions', 'routines', pillar, 'selected'],
          false,
        );
      });
      return updatedFormData;
    });

  }, [setFormData, routinesGroupedByPillar]);

  const onPillarSelection = (pillar: string) => {
    if (activePillar === pillar) {
      setActivePillar(null);
      props.setFormData((formData: any) => {
        let updatedFormData = formData;
        Object.keys(routinesGroupedByPillar).forEach((currentPillar: string) => {
          updatedFormData = updatedFormData.setIn(
            ['permissions', 'routines', currentPillar, 'selected'],
            false,
          );
        });
        return updatedFormData;
      });
    } else {
      setActivePillar(pillar);
      props.setFormData((formData: any) => {
        let updatedFormData = formData;

        Object.keys(routinesGroupedByPillar).forEach((currentPillar: string) => {
          updatedFormData = updatedFormData.setIn(
            ['permissions', 'routines', currentPillar, 'selected'],
            false,
          );
        });
        return updatedFormData.setIn(
          ['permissions', 'routines', pillar, 'selected'],
          true,
        );
      });
    }
  };

  const onToggle = (path: (string | number)[]) => {
    props.setFormData((formData: any) => formData.setIn(
      ['permissions', 'routines', ...path],
      !formData.getIn(['permissions', 'routines', ...path]),
    ));
  };

  const revokePermissionOfAllRoutines = () => {
    const routinesGroupedByPillarClone = cloneDeep(routinesGroupedByPillar);
    Object.values(routinesGroupedByPillarClone).forEach(routinesByPillar => {
      routinesByPillar.routines.forEach(routine => {
        routine.granted = false;
      });
    });
    props.setFormData((formData: any) => formData.setIn(['permissions', 'routines'], fromJS(routinesGroupedByPillarClone)));
  };

  const filterRoutinesBySearch = pillar => {
    const { routines } = routinesGroupedByPillar[pillar];

    if (!searchQuery.trim()) return routines;

    return routines.filter(routine =>
      routine.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };

  const pillarsToDisplay = Object.keys(routinesGroupedByPillar).filter(pillar => {
    if (activePillar === null) {
      return true;
    }
    return pillar === activePillar;
  });

  return (
    <>
      <input
        type="text"
        placeholder={t(TRANSLATIONS.SEARCH)}
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        style={{ border: '1px solid #a6a6a6' }}
      />
      <div className="row select_all_container">
        <div className="d-flex justify-content-top flex-column align-items-stretch select_all">
          {Object.keys(routinesGroupedByPillar).map((pillar: string) => (
            <button
              className={`filter_button ${
                activePillar === pillar
                  ? 'filter-button-active'
                  : 'filter-button-inactive'
              }`}
              onClick={() => onPillarSelection(pillar)}
              type="button"
              key={pillar}
            >
              {capitalizeFirst(pillar)} (
              {getCountOfGrantedRoutines(routinesGroupedByPillar[pillar])})
            </button>
          ))}
        </div>
        <div className="routineList">
          {pillarsToDisplay.map((pillar: string) => {
            const filteredRoutines = filterRoutinesBySearch(pillar);

            return (
              <div className="routineContainer py-1" key={pillar}>
                <h4>
                  {capitalizeFirst(pillar)} (
                  {filteredRoutines.filter(r => r.granted).length} /{' '}
                  {filteredRoutines.length})
                </h4>
                {filteredRoutines.length > 0 ?
                  filteredRoutines.map(routine => {
                    const originalIndex = routinesGroupedByPillar[pillar].routines.findIndex(
                      r => r.slug === routine.slug,
                    );

                    return (
                      <CheckBoxWithIcon
                        onClick={() => {
                          if (routine.editable === false) {
                            return;
                          }
                          const permissionPath = [pillar, 'routines', originalIndex, 'granted'];
                          onToggle(permissionPath);
                        }}
                        deSelectedIcon={emptyRadioUnchecked}
                        selectedIcon={yellowRadioChecked}
                        selected={routine.granted}
                        label={routine.name}
                        key={routine.slug}
                        icon={<OrangeCheckbox
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                          checked={routine.granted}
                        />}
                      />
                    );
                  })
                  : <p>No matching routines found</p>
                }
              </div>
            );
          })}
        </div>
        <div className="remove-all">
          <button onClick={revokePermissionOfAllRoutines} type="button" style={{ color: '#CAA038' }}>
            Quitar todas
          </button>
        </div>
      </div>
    </>
  );
};

export default RoutinesSelector;
